export function setCookie (key, value, t) {
    const k = encodeURIComponent(key);
    const v = encodeURIComponent(value);
    let oDate = new Date();
    oDate.setDate(oDate.getDate() + t);
    document.cookie = k + "=" + v + "; expires=" + oDate.toUTCString() + "; path=/";
}
/**
 * [getCookie 获取cookie]
 */
export function getCookie (key) {
    const cookies = document.cookie.split("; ");//由于cookie是通过一个分号+空格的形式串联起来的，所以这里需要先按分号空格截断,变成[name=Jack,pwd=123456,age=22]数组类型；
    for (let i = 0; i < cookies.length; i++) {
        const arr = cookies[i].split("=");//通过=截断，把name=Jack截断成[name,Jack]数组；
        if (arr[0] === key) {
            return decodeURIComponent(arr[1]);
        }
    }
}
/**
 * [removeCookie 移除cookie]
 */
export function removeCookie (key) {
    setCookie(key, "", -1); // 把cookie设置为过期
}
/**
 * 流下载文件
 */
export function blobFileDownload (data, fileName) {
    const blob = new Blob([data]);//处理文档流
    const elink = document.createElement('a');
    elink.download = fileName;
    elink.style.display = 'none';
    elink.href = URL.createObjectURL(blob);
    document.body.appendChild(elink);
    elink.click();
    URL.revokeObjectURL(elink.href); // 释放URL 对象
    document.body.removeChild(elink);
}

export function blobToJSON (data) {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.readAsText(data, 'utf-8');
        reader.onload = function () {
            try {
                resolve(JSON.parse(reader.result));
            } catch (error) {
                reject({ code: '400000', message: `blob转换json失败: ${error}` });
            }
        };
    });
}

/**
 * B, KB, MB, GB
 */
export function transformFileSize (size) {
    if (!size) {
        return '';
    }
    if (size > 0 && size < 1024) {
        return size + 'B';
    } else if (size >= 1024 && size < 1024 * 1024) {
        return (size / 1024).toFixed(2) + 'KB';
    } else if (size >= 1024 * 1024 && size < 1024 * 1024 * 1024) {
        return (size / 1024 / 1024).toFixed(2) + 'MB';
    } else {
        return (size / 1024 / 1024 / 1024).toFixed(2) + 'GB';
    }
}


/**
 * 
 * 图片url 转换到oss代理
 */

export function urlToOssProxy (url) {
    // const url = 'http://dm-cms-oss-test.oss-cn-baoding-gwmcloud-d01-a.ops.cloud.gwm.cn:80/CAM%2F686R91%2FpublishTabSelected.png';
    const reg = /http(s)?:\/\/dm-cms(-oss)?(-test)?\.oss-cn-baoding-gwmcloud-d01-a\.ops\.cloud\.gwm\.cn(:80)?\/(?<fileName>[^?]+)(\?)?.*/;
    if (reg.test(url)) {
        let sourceFileName = url.match(reg).groups.fileName;
        let fetchUrl = `${(process.env.NODE_ENV === 'development' ? '/proxyfile' : process.env.VUE_APP_FORE_END_URL)}/oss/${sourceFileName}`;
        // const resultUrl = await axios({
        //     method: 'get',
        //     url: `${fetchUrl}`,
        //     headers: {},
        //     responseType: 'blob',
        // }).then((res) => {
        //     const { data, status } = res;
        //     if (status === 200) {
        //         const blob = new Blob([data]);//处理文档流
        //         const url = URL.createObjectURL(blob);
        //         return url;
        //     }
        // })
        //     .catch(() => {
        //     });
        // return resultUrl || '';
        return fetchUrl;
    }
    return url;
}