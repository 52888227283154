import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';

const state = {
  userName: '', // 用户名称
  userId: '', // 用户id
  userNum: '', // 工号
  groupName: '', // 部门
  dutyName: '', // 岗位
  headImg: '', // 头像
  brandId: null, // 品牌id
  sex: null, // 性别 1:男 2:女
};
const namespaced = true;
export const user = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
export default state;