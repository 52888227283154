import { createRouter, createWebHistory } from 'vue-router';
import {
  getCookie,
} from '@/utils/index';

const routes = [
  {
    path: '/',
    redirect: '/main'
  },
  {
    path: '/main',
    name: 'main',
    meta: {
      auth: true,
      title: 'main'
    },
    component: () => import(/* webpackChunkName: "main" */ '@/views/main/index.vue'),
    children: [
      // {
      //   path: '/main',
      //   redirect: '/main/brand'
      // },
      {
        path: '/main/brand',
        name: '品牌管理',
        component: () => import(/* webpackChunkName: "brand" */ '@/views/brand/index.vue'),
      },
      {
        path: '/main/carmodel',
        name: '车型管理',
        component: () => import(/* webpackChunkName: "carmodel" */ '@/views/carModel/index.vue'),
      },
      {
        path: '/main/taglib',
        name: '标签管理',
        component: () => import(/* webpackChunkName: "taglib" */ '@/views/taglib/index.vue'),
      },
      {
        path: '/main/taglibdetails',
        name: '标签库详情',
        component: () => import(/* webpackChunkName: "taglibdetails" */ '@/views/taglibDetails/index.vue'),
      },
      {
        path: '/main/channel',
        name: '渠道管理',
        component: () => import(/* webpackChunkName: "taglib" */ '@/views/channel/index.vue'),
      },
      {
        path: '/main/system/rulemanage',
        name: '角色管理',
        component: () => import(/* webpackChunkName: "rulemanage" */ '@/views/ruleManage/index.vue'),
      },
      {
        path: '/main/system/usermanage',
        name: '人员管理',
        component: () => import(/* webpackChunkName: "usermanage" */ '@/views/userManage/index.vue'),
      },
      {
        path: '/main/system/departmentmanage',
        name: '部门管理',
        component: () => import(/* webpackChunkName: "departmentmanage" */ '@/views/departmentManage/index.vue'),
      },
      {
        path: '/main/dictionary',
        name: '字典管理',
        component: () => import(/* webpackChunkName: "dictionary" */ '@/views/dictionary/index.vue')
      }
    ]
  },
  /** 登录页暂时不用 */
  {
    path: '/login',
    name: 'Login',
    meta: {
      auth: false,
      title: '登录页'
    },
    component: () => import(/* webpackChunkName: "login" */ '@/views/login/index.vue')
  },
  {
    path: '/ssologin',
    name: 'SSOLogin',
    meta: {
      auth: false,
      title: '单点登录回调页'
    },
    component: () => import(/* webpackChunkName: "ssologin" */ '@/views/ssoLogin/index.vue')
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});
router.beforeEach(async (to, from, next) => {
  // if (to.matched.some(record => record.meta.auth) && to.meta.auth) { // 判断该路由是否需要登录权限
  // document.documentElement.scrollTop = 0;
  if (to.matched.some(record => record.meta.auth)) { // 判断该路由是否需要登录权限
    const accessToken = getCookie("accessToken");
    if (accessToken) { // 获取当前的 token 是否存在
      next();
    } else {
      // 不存在 token，需要重新认证
      // next({
      //   path: '/login',
      //   query: {
      //     redirect: to.fullPath
      //   }
      // });
      window.open(`${process.env.VUE_APP_FORE_END_URL}/login`, '_self');
    }
  } else {
    next();
  }
});

export default router;
