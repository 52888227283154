<template>
  <svg :class="svgClass" aria-hidden="true" @click="$emit('click')">
    <use :xlink:href="iconName" />
  </svg>
</template>

<script>
export default {
  name: 'SvgIcon',
  props: {
    iconClass: {
      type: String,
      required: true
    },
    className: {
      type: String,
      default: ""
    }
  },
  computed: {
    iconName () {
      return `#${this.iconClass}`;
    },
    svgClass () {
      if (this.className) {
        return "svg-icon " + this.className;
      } else {
        return "svg-icon";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.svg-icon {
  width: 20rem;
  height: 20rem;
  vertical-align: 0.15rem;
  fill: currentColor;
  overflow: hidden;
}
</style>
