import { createApp } from 'vue';
import ElementPlus from "element-plus";
import zhCn from "element-plus/es/locale/lang/zh-cn";

import IntoProvidePlugin from '@/plugins/into-provide-plugin';

import App from './App.vue';
import router from './router';
import store from './store';

import "@/theme/element-variables.scss";
import SvgIcon from "@/icons/index";

const app = createApp(App);
app.component("svg-icon", SvgIcon);
app.use(ElementPlus, {
  locale: zhCn,
});

app.use(IntoProvidePlugin);

app.use(store);
app.use(router);
app.mount('#app');
