<template>
  <router-view />
</template>

<style>
html,
body {
  margin: 0;
  font-family: "PingFang SC";
  scroll-behavior: smooth;
  font-size: 12rem;
}
body {
  width: 100vw;
  overflow: hidden;
  overflow-y: auto;
}
@media screen and (max-width: 1280px) {
  body {
    overflow: auto !important;
  }
}
body {
  overflow: hidden;
  overflow-y: auto;
}
ul,
li {
  margin: 0;
  padding: 0;
}
/** message 提示样式修改 */
.el-message .el-icon,
.el-message .el-icon svg {
  height: 2em;
  width: 2em;
}

/** table 最小高度 */
/* .el-scrollbar__view {
  min-height: 400px;
} */
/* 更改表格分界线 */
/* .el-table__inner-wrapper::before{
  z-index: -1 !important;
} */
</style>
