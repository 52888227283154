
import { provide, reactive, readonly } from 'vue';
export default {
  install: (app) => {
    app.mixin({
      created () {
        const { Basemodule } = this.$options;
        if (Basemodule) {
          const { state = () => { return {}; }, action = () => { return {}; }, name = 'rootModule' } = Basemodule;
          /** 注入state 和 action */
          let tState = state;
          if (Object.prototype.toString.call(state) !== '[object Function]') {
            tState = () => {
              return {
                ...state
              };
            };
          }
          const moduleState = reactive({ ...tState() });
          const moduleAction = reactive({ ...action(moduleState) });
          provide(`${name}State`, readonly(moduleState));
          provide(`${name}Action`, readonly(moduleAction));
        }
      }
    });
  }
};