import service from '@/utils/service';
import urls from '@/utils/urls';
import { ElMessageBox } from 'element-plus';
import {
  removeCookie,
  setCookie,
} from '@/utils/index';
// const dataMock = [
//   {
//     "id": 1,
//     "menuType": 1,
//     "parentId": 0,
//     "title": "品牌管理",
//     "code": "sys:brand:view",
//     "iconPic": "",
//     "status": 1,
//     "sort": 1,
//     "requestUrl": "/122/yy",
//     "path": "/122",
//     "children": [
//       {
//         "id": 7,
//         "menuType": 2,
//         "parentId": 1,
//         "title": "新增品牌",
//         "code": "sys:brand:add",
//         "iconPic": "",
//         "status": 1,
//         "sort": 1,
//         "requestUrl": "/edit",
//         "path": "/edit",
//         "children": []
//       },
//       {
//         "id": 8,
//         "menuType": 2,
//         "parentId": 1,
//         "title": "设置品牌审核流程",
//         "code": "sys:brand:setup",
//         "iconPic": "",
//         "status": 1,
//         "sort": 2,
//         "requestUrl": "/edit/1",
//         "path": "/edit/1",
//         "children": []
//       },
//       {
//         "id": 9,
//         "menuType": 2,
//         "parentId": 1,
//         "title": "编辑品牌",
//         "code": "sys:brand:edit",
//         "iconPic": "",
//         "status": 1,
//         "sort": 3,
//         "requestUrl": "/edit/1",
//         "path": "/edit/1",
//         "children": []
//       },
//       {
//         "id": 10,
//         "menuType": 2,
//         "parentId": 1,
//         "title": "开启关闭品牌",
//         "code": "sys:brand:switch",
//         "iconPic": "",
//         "status": 1,
//         "sort": 4,
//         "requestUrl": "/edit",
//         "path": "/edit",
//         "children": []
//       }
//     ]
//   },
//   {
//     "id": 2,
//     "menuType": 1,
//     "parentId": 0,
//     "title": "车型管理",
//     "code": "sys:carmodel:view",
//     "iconPic": "",
//     "status": 1,
//     "sort": 2,
//     "requestUrl": "/123/yy",
//     "path": "/123",
//     "children": [
//       {
//         "id": 11,
//         "menuType": 2,
//         "parentId": 2,
//         "title": "新增车型",
//         "code": "sys:carmodel:add",
//         "iconPic": "",
//         "status": 1,
//         "sort": 1,
//         "requestUrl": "/edit",
//         "path": "/edit",
//         "children": []
//       },
//       {
//         "id": 12,
//         "menuType": 2,
//         "parentId": 2,
//         "title": "设置车型审核流程",
//         "code": "sys:carmodel:setup",
//         "iconPic": "",
//         "status": 1,
//         "sort": 2,
//         "requestUrl": "/edit/1",
//         "path": "/edit/1",
//         "children": []
//       },
//       {
//         "id": 13,
//         "menuType": 2,
//         "parentId": 2,
//         "title": "编辑车型",
//         "code": "sys:carmodel:edit",
//         "iconPic": "",
//         "status": 1,
//         "sort": 3,
//         "requestUrl": "/edit/1",
//         "path": "/edit/1",
//         "children": []
//       },
//       {
//         "id": 14,
//         "menuType": 2,
//         "parentId": 2,
//         "title": "开启关闭车型",
//         "code": "sys:carmodel:switch",
//         "iconPic": "",
//         "status": 1,
//         "sort": 4,
//         "requestUrl": "/edit",
//         "path": "/edit",
//         "children": []
//       }
//     ]
//   },
//   {
//     "id": 3,
//     "menuType": 1,
//     "parentId": 0,
//     "title": "标签管理",
//     "code": "sys:tag:view",
//     "iconPic": "",
//     "status": 1,
//     "sort": 3,
//     "requestUrl": "/124/yyyy",
//     "path": "/124",
//     "children": [
//       {
//         "id": 15,
//         "menuType": 2,
//         "parentId": 3,
//         "title": "标签库新增",
//         "code": "sys:tags:add",
//         "iconPic": "",
//         "status": 1,
//         "sort": 1,
//         "requestUrl": "/edit",
//         "path": "/edit",
//         "children": []
//       },
//       {
//         "id": 16,
//         "menuType": 2,
//         "parentId": 3,
//         "title": "标签库编辑",
//         "code": "sys:tags:edit",
//         "iconPic": "",
//         "status": 1,
//         "sort": 2,
//         "requestUrl": "/edit/1",
//         "path": "/edit/1",
//         "children": []
//       },
//       {
//         "id": 17,
//         "menuType": 2,
//         "parentId": 3,
//         "title": "标签库删除",
//         "code": "sys:tags:del",
//         "iconPic": "",
//         "status": 1,
//         "sort": 3,
//         "requestUrl": "/edit/1",
//         "path": "/edit/1",
//         "children": []
//       },
//       {
//         "id": 18,
//         "menuType": 2,
//         "parentId": 3,
//         "title": "标签新增",
//         "code": "sys:tag:add",
//         "iconPic": "",
//         "status": 1,
//         "sort": 5,
//         "requestUrl": "/edit",
//         "path": "/edit",
//         "children": []
//       },
//       {
//         "id": 9999,
//         "menuType": 2,
//         "parentId": 3,
//         "title": "标签库详情",
//         "code": "sys:tag:add",
//         "iconPic": "",
//         "status": 1,
//         "sort": 5,
//         "requestUrl": "/edit",
//         "path": "/edit",
//         "children": []
//       },
//       {
//         "id": 19,
//         "menuType": 2,
//         "parentId": 3,
//         "title": "标签编辑",
//         "code": "sys:tag:edit",
//         "iconPic": "",
//         "status": 1,
//         "sort": 6,
//         "requestUrl": "/edit/1",
//         "path": "/edit/1",
//         "children": []
//       },
//       {
//         "id": 20,
//         "menuType": 2,
//         "parentId": 3,
//         "title": "标签删除",
//         "code": "sys:tag:del",
//         "iconPic": "",
//         "status": 1,
//         "sort": 7,
//         "requestUrl": "/edit/1",
//         "path": "/edit/1",
//         "children": []
//       },
//       {
//         "id": 21,
//         "menuType": 2,
//         "parentId": 3,
//         "title": "标签开启关闭",
//         "code": "sys:tag:switch",
//         "iconPic": "",
//         "status": 1,
//         "sort": 8,
//         "requestUrl": "/edit",
//         "path": "/edit",
//         "children": []
//       }
//     ]
//   },
//   {
//     "id": 4,
//     "menuType": 1,
//     "parentId": 0,
//     "title": "系统管理",
//     "code": "sys:view",
//     "iconPic": "",
//     "status": 1,
//     "sort": 4,
//     "requestUrl": "/125/yyy",
//     "path": "/125",
//     "children": [
//       {
//         "id": 5,
//         "menuType": 1,
//         "parentId": 4,
//         "title": "人员管理",
//         "code": "sys:user:view",
//         "iconPic": "",
//         "status": 1,
//         "sort": 1,
//         "requestUrl": "/126/yyy",
//         "path": "/126",
//         "children": [
//           {
//             "id": 22,
//             "menuType": 2,
//             "parentId": 5,
//             "title": "新增人员",
//             "code": "sys:user:add",
//             "iconPic": "",
//             "status": 1,
//             "sort": 1,
//             "requestUrl": "/edit",
//             "path": "/edit",
//             "children": ""
//           },
//           {
//             "id": 23,
//             "menuType": 2,
//             "parentId": 5,
//             "title": "编辑人员",
//             "code": "sys:user:edit",
//             "iconPic": "",
//             "status": 1,
//             "sort": 2,
//             "requestUrl": "/edit/1",
//             "path": "/edit/1",
//             "children": ""
//           },
//           {
//             "id": 24,
//             "menuType": 2,
//             "parentId": 5,
//             "title": "删除人员",
//             "code": "sys:user:del",
//             "iconPic": "",
//             "status": 1,
//             "sort": 3,
//             "requestUrl": "/edit/1",
//             "path": "/edit/1",
//             "children": ""
//           },
//           {
//             "id": 25,
//             "menuType": 2,
//             "parentId": 5,
//             "title": "交接人员",
//             "code": "sys:user:join",
//             "iconPic": "",
//             "status": 1,
//             "sort": 4,
//             "requestUrl": "/edit",
//             "path": "/edit",
//             "children": ""
//           }
//         ]
//       },
//       {
//         "id": 6,
//         "menuType": 1,
//         "parentId": 4,
//         "title": "角色管理",
//         "code": "sys:role:view",
//         "iconPic": "",
//         "status": 1,
//         "sort": 2,
//         "requestUrl": "/api/role/listAll",
//         "path": "/127",
//         "children": [
//           {
//             "id": 26,
//             "menuType": 2,
//             "parentId": 6,
//             "title": "新增角色",
//             "code": "sys:role:add",
//             "iconPic": "",
//             "status": 1,
//             "sort": 1,
//             "requestUrl": "/api/role/add",
//             "path": "/edit",
//             "children": ""
//           },
//           {
//             "id": 27,
//             "menuType": 2,
//             "parentId": 6,
//             "title": "编辑角色",
//             "code": "sys:role:edit",
//             "iconPic": "",
//             "status": 1,
//             "sort": 2,
//             "requestUrl": "/edit/1",
//             "path": "/edit/1",
//             "children": ""
//           },
//           {
//             "id": 28,
//             "menuType": 2,
//             "parentId": 6,
//             "title": "删除角色",
//             "code": "sys:role:del",
//             "iconPic": "",
//             "status": 1,
//             "sort": 3,
//             "requestUrl": "/edit/1",
//             "path": "/edit/1",
//             "children": ""
//           },
//           {
//             "id": 29,
//             "menuType": 2,
//             "parentId": 6,
//             "title": "角色详情",
//             "code": "sys:role:details",
//             "iconPic": "",
//             "status": 1,
//             "sort": 4,
//             "requestUrl": "/edit",
//             "path": "/edit",
//             "children": ""
//           }
//         ]
//       }
//     ]
//   }
// ];
function logout () {
  service.post(urls.logout, {})
    .then(() => {
      removeCookie('accessToken');
      window.open(`${process.env.VUE_APP_FORE_END_URL}/login`, '_self');
    })
    .catch((error) => {
      console.error(error);
    });
}
// 格式化数据
function authInit (dataList) {
  function initFn (obj) {
    if (obj.children && obj.children.length) {
      let children = [];
      let func = [];
      obj.children.map((item) => {
        if (item.menuType === 1) {
          children.push(initFn(item));
        } else {
          func.push(item.title);
        }
      });
      return {
        title: obj.title,
        path: '',
        func,
        children,
      };
    }
    return {
      title: obj.title,
      path: '',
      func: [],
      children: [],
    };
  }
  const result = dataList.map((item) => {
    return initFn(item);
  });
  return result;
}
export const actions = {
  setUserInfoAsync ({ commit }, params) {
    console.log('setuserinfo', params);
    service.get(`${urls.getUserInfo}`, {})
      .then((res) => {
        const { body } = res;
        const userInfo = body.userInfo;
        if (!userInfo.brandId) {
          ElMessageBox.alert('当前用户未分配品牌权限，请联系冯金宇GW00274758，了解账号权限开通情况。', '提示', {
            confirmButtonText: '知道了',
            callback: () => {
              logout();
            }
          });
          return;
        }
        setCookie('accessToken', body.accessToken, 30); // 替换 成新的token
        const authList = authInit(userInfo.children);
        commit('setUserInfo', {
          userName: userInfo.userName, // 用户名称
          userNum: userInfo.userCode, // 工号
          groupName: userInfo.groupName, // 部门
          dutyName: userInfo.dutyName, // 岗位
          headImg: userInfo.portrait ? JSON.parse(userInfo.portrait).url : '', // 头像
          brandId: userInfo.brandId, // 品牌id
          sex: userInfo.sex, // 性别
          authList: authList,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }
};