import axios from "axios";

import { ElMessage } from 'element-plus';
// import router from '@/router';
import {
    getCookie,
} from '@/utils/index';

const instance = axios.create({
    baseURL: process.env.NODE_ENV === 'development' ? '/proxyapi' : process.env.VUE_APP_API,
    // timeout: 10000,
    // headers: {'X-Custom-Header': 'foobar'}
});
// 请求拦截
instance.interceptors.request.use(function (config) {
    const accessToken = getCookie("accessToken");
    const { headers = {} } = config;
    if (accessToken) {
        headers.accessToken = accessToken;
        config.headers = headers;
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});
// 响应拦截器
instance.interceptors.response.use(async function (response) {
    let { data, status, statusText, headers } = response;

    if (status !== 200) {
        ElMessage.error(`${statusText}`);
        return Promise.reject({ status: status, statusText: statusText });
    }

    // "000000", "SUCCESS"
    // "100000", "业务错误"
    // "200000", "参数校验错误"
    // "200001", "校验缺失"
    // "300000", "权限不足"
    // "400000", "系统错误"
    // "400001", "请求SSO认证中心校验token异常"
    if (data.code !== '000000') {
        // code码为token失效状态时需要重新登录
        if (data.code === '300001' || data.code === '400001' || data.code === '500000') {
            // router.replace({
            //     name: 'Login',
            //     query: { redirect: router.currentRoute.fullPath } // 当前的路径
            // });
            window.open(`${process.env.VUE_APP_FORE_END_URL}/login`, '_self');
        }
        ElMessage.error(`${data.message}`);
        return Promise.reject({ code: data.code, message: data.message });
    }
    return {
        ...data,
        headers,
    };

}, function (error) {
    return Promise.reject(error);
});
export default instance;